<template>
  <div class="flex-col w-full space-y-2">
    <select
      name="preset"
      class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      v-model="selected"
    >
      <option disabled value="">Selecteer een afmeting</option>

      <option
        :value="key"
        v-for="(preset, key) in presets"
        :disabled="
          preset.width < maxDimensions.width ||
          preset.height < maxDimensions.height
        "
        :key="key"
      >
        {{ preset.name }}
      </option>
    </select>
    <div>
      <button
        type="button"
        class="rounded-md w-full block bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
        @click="savePreset"
      >
        Kies afmetingen
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
const emit = defineEmits(["pickPresetSize"]);

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { useMakerStore } from "@/stores/maker";
import { storeToRefs } from "pinia";
const store = useMakerStore();
const presets = [
  { width: 543, height: 773, name: "Type 7800 Trolley 543x773mm 305diep" },
  { width: 143, height: 204, name: "Type 500 143x204mm 60mm diep" },
  { width: 249, height: 176, name: "Type 1000 249x176mm 66mm diep" },
  { width: 178, height: 248, name: "Type 2000 178x248mm 127diep" },
  { width: 233, height: 332, name: "Type 3000 top 233x332mm 110diep" },
  { width: 264, height: 387, name: "Type 4000 264x387mm 117diep" },
  { width: 304, height: 435, name: "Type 5000 304x435mm 124 diep" },
  { width: 303, height: 436, name: "Type 5040 303x436mm 77mm diep" },
  { width: 303, height: 435, name: "Type 5500 303x435mm 254mm diep" },
  { width: 352, height: 475, name: "Type 6000 352x475mm 148mm diep" },
  { width: 355, height: 477, name: "Type 6040 355x477mm 80mm diep" },
  { width: 416, height: 586, name: "Type 6500 416x586mm 157mm diep" },
  { width: 282, height: 500, name: "Type 6600 Trolley 282x500mm 148diep" },
  { width: 363, height: 537, name: "Type 6700 Trolley 363x537mm 175diep" },
  { width: 419, height: 589, name: "Type 6800 Trolley 419x589mm 240diep" },
  { width: 438, height: 1085, name: "Type 7200 Trolley 438x1085mm 135diep" },
  { width: 439, height: 1089, name: "Type 7300 Trolley 439x1089mm 260diep" },
];
const maxDimensions = ref({ width: 0, height: 0 });
const savePreset = function () {
  emit("pickPresetSize", presets[selected.value]);
  selected.value = "";
};

onMounted(() => {
  maxDimensions.value = store.getMaxDimensionsForUploadedModels();
});

const selected = ref("");
</script>
