import { createApp } from "vue";
import { createPinia } from "pinia";

import Editor from "./components/Editor.vue";

import "./assets/main.css";

const app = createApp(Editor);
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import Notifications from "notiwind";

const pinia = createPinia();
app.use(pinia);
app.use(Notifications);

// app.use(router);
pinia.use(piniaPluginPersistedState);

app.mount("#laserFrezerDxf");
