<template>
  <div class="flex flex-col h-full">
    <div
      class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
      v-if="!imageSrc"
    >
      <div class="space-y-1 text-center">
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="flex text-sm text-gray-600">
          <label
            for="file-upload"
            class="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
          >
            <span>Upload a file</span>
            <input
              id="file-upload"
              name="file-upload"
              ref="imageInput"
              type="file"
              accept="image/png, image/jpeg"
              class="sr-only"
              @change="storeFile"
            />
          </label>
          <p class="pl-1">or drag and drop</p>
        </div>
        <p class="text-xs text-gray-500">PNG, JPG tot 1 mb</p>
      </div>
    </div>
    <cropper
      v-if="cropperVisible"
      ref="cropperRef"
      class="cropper"
      :src="imageSrc"
      @change="change"
      :canvas="{
        minHeight: 0,
        minWidth: 0,
        maxHeight: 750,
        maxWidth: 750,
      }"
    ></cropper>

    <div class="w-1/3 p-2" v-if="cropperVisible">
      <label class="block text-sm font-medium leading-6 text-gray-900"
        >Afbeelding aantal graden draaien (rechtsom)</label
      >
      <div class="mt-2">
        <input
          type="number"
          v-model.number="rotateDegrees"
          class="inline-block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <button
        type="button"
        @click="cropperRotate()"
        class="rounded bg-orange-600 px-2 mt-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
      >
        Draai
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import { useNewItemWizardStore } from "@/stores/newitemwizard";
const wizardStore = useNewItemWizardStore();

const rotateDegrees = ref(1);
const imageSrc = ref(null);
const imageInput = ref(null);
const cropperVisible = ref(false);
const croppedImage = ref(null);
const croppedImageEl = ref(null);

const cropperRef = ref(null);

const imageWidth = ref(null);
const imageHeight = ref(null);

const cropperCoordinates = reactive({
  width: 0,
  height: 0,
  left: 0,
  top: 0,
});
const change = ({ canvas }) => {
  croppedImage.value = canvas.toBlob((blob) => {
    wizardStore.saveCroppedImageBlob(blob);
  });
};

const cropperRotate = () => {
  cropperRef.value.rotate(rotateDegrees.value);
};

const storeFile = (event) => {
  var reader = new FileReader();

  //Read the contents of Image File.
  reader.readAsDataURL(event.target.files[0]);
  reader.onload = function (e) {
    //Initiate the JavaScript Image object.
    var image = new Image();

    //Set the Base64 string return from FileReader as source.
    imageSrc.value = e.target.result;
    image.src = e.target.result;

    //Validate the File Height and Width.
    image.onload = function () {
      imageHeight.value = this.height;
      imageWidth.value = this.width;
    };
  };

  startCropper();
};

const startCropper = () => {
  cropperVisible.value = true;
};
</script>

<style scoped>
.cropper {
  max-height: calc(100vh - 400px);
}
</style>
