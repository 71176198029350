<template>
  <div
    v-html="svgElement"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
    @mousemove="handleDragging"
    @dblclick="handleElementDoubleClicked"
    @mouseover="handleElementHovered"
    @mouseleave="handleElementHoveredLeave"
  ></div>
</template>

<script setup>
const emit = defineEmits([
  "elementMoved",
  "elementDoubleClicked",
  "elementHovered",
  "elementHoveredLeave",
]);
import { ref, watch } from "vue";
const props = defineProps(["svgElement", "outsideBounds", "panzoomScale"]);

const dragging = ref(false);

const mouseDownX = ref(null);
const mouseDownY = ref(null);

const diffX = ref(null);
const diffY = ref(null);

const clickedTarget = ref(null);
const oldTransform = ref(null);

const handleElementDoubleClicked = (e) => {
  emit("elementDoubleClicked", e);
};

const handleElementHovered = (e) => {
  emit("elementHovered", e);
};
const handleElementHoveredLeave = (e) => {
  emit("elementHoveredLeave", e);
};

const isOutOfViewport = (elementBounds, outerBounds) => {
  // Check if it's out of the viewport on each side
  let out = {};
  out.top = elementBounds.top < outerBounds.top;
  out.left = elementBounds.left < outerBounds.left;
  out.bottom = elementBounds.bottom > outerBounds.bottom;
  out.right = elementBounds.right > outerBounds.right;
  out.any = out.top || out.left || out.bottom || out.right;
  out.all = out.top && out.left && out.bottom && out.right;
  return out;
};
const handleDragging = (e) => {
  if (dragging.value === false) {
    return;
  }

  const oldXValue = diffX.value;
  const oldYValue = diffY.value;
  diffX.value = (e.clientX - mouseDownX.value) / props.panzoomScale.scale;

  diffY.value = (e.clientY - mouseDownY.value) / props.panzoomScale.scale;
  oldTransform.value = clickedTarget.value.style.transform;

  clickedTarget.value.style.transform = `translate(${diffX.value}px, ${diffY.value}px) `;

  let outOfViewport = isOutOfViewport(
    clickedTarget.value.getBoundingClientRect(),
    props.outsideBounds
  );
  if (outOfViewport.left || outOfViewport.right) {
    diffX.value = oldXValue;
    clickedTarget.value.style.transform = `translate(${oldXValue}px, ${diffY.value}px) `;
  }
  if (outOfViewport.top || outOfViewport.bottom) {
    diffY.value = oldYValue;
    clickedTarget.value.style.transform = `translate(${diffX.value}px, ${oldYValue}px) `;
  }
};
const handleMouseDown = (e) => {
  if (e.target.tagName !== "g") {
    return;
  }
  dragging.value = true;
  mouseDownX.value = e.clientX;
  mouseDownY.value = e.clientY;
  clickedTarget.value = e.target;
};

const handleMouseUp = (e) => {
  if (clickedTarget.value === null) {
    return;
  }
  let outOfViewport = isOutOfViewport(
    clickedTarget.value.getBoundingClientRect(),
    props.outsideBounds
  );

  if (outOfViewport.any) {
    clickedTarget.value.style.transform = `translate(0px, 0px) `;
  } else {
    emit("elementMoved", {
      movedElement: clickedTarget.value,
      diffX: diffX.value,
      diffY: diffY.value,
    });
  }
  dragging.value = false;
  mouseDownX.value = null;
  mouseDownY.value = null;
  clickedTarget.value = null;
  diffX.value = null;
  diffY.value = null;
};
watch(
  () => props.svgElement,
  () => {
    ("svg element changed");
  }
);
</script>

<style>
g#captions text {
  opacity: 0;
}
</style>
