import { ref, computed, reactive, shallowRef } from "vue";
import { defineStore } from "pinia";
import UploadCropPhoto from "@/components/WizardSteps/UploadCropPhoto.vue";
import EditResult from "@/components/WizardSteps/EditResult.vue";
import MarchingAnts from "@/tracers/marching-ants";

export const useNewItemWizardStore = defineStore("newitemwizard", () => {
  const currentStep = ref(0);
  const steps = ref([
    {
      title: "Step 1",
      content: "Upload and crop a photo",
      component: shallowRef(UploadCropPhoto),
    },
    {
      title: "Step 2",
      content: "Generating outline",
      component: shallowRef(EditResult),
    },
  ]);

  function prevStep() {
    currentStep.value -= 1;
  }
  function nextStep() {
    currentStep.value += 1;
  }

  function setCannotFitError(value) {
    cannotFitError.value = value;
  }
  const createModalOpen = ref(false);

  const cannotFitError = ref(null);

  const croppedImageBlob = ref(null);

  const backgroundRemovalLoading = ref(false);

  const itemWidth = ref(0);
  const itemHeight = ref(0);
  const itemDepth = ref(0);
  const itemName = ref(null);

  const imageWidth = ref(0);
  const imageHeight = ref(0);

  const originalSvg = ref(null);
  const updatedSvg = ref(null);

  const imageWithoutBackground = ref(null);

  function openModal() {
    createModalOpen.value = true;
  }
  function closeModal() {
    createModalOpen.value = false;
    $reset();
  }
  function saveCroppedImageBlob(imageBlob) {
    croppedImageBlob.value = imageBlob;
  }

  function $reset() {
    currentStep.value = 0;
    createModalOpen.value = false;
    croppedImageBlob.value = null;
    backgroundRemovalLoading.value = false;
    itemWidth.value = null;
    itemHeight.value = null;
    itemDepth.value = null;
    itemName.value = null;
    originalSvg.value = null;
    updatedSvg.value = null;
    imageWithoutBackground.value = null;
  }

  async function sendCroppedImageBlob() {
    let formData = new FormData();
    backgroundRemovalLoading.value = true;

    formData.append("file", croppedImageBlob.value);
    formData.append("model", "isnet-general-use");

    let response = await fetch("https://dxf-tool.delaserfrezer.com/trace/", {
      method: "POST",
      body: formData,
    });

    let blob = await response.blob();

    imageWithoutBackground.value = URL.createObjectURL(blob);
    const imagetracerClass = new MarchingAnts();
    let traceResult = await imagetracerClass.trace(
      imageWithoutBackground.value
    );
    originalSvg.value = traceResult.path;
    imageHeight.value = traceResult.height;
    imageWidth.value = traceResult.width;

    backgroundRemovalLoading.value = false;
  }

  const itemForCanvas = () => {
    return {
      path: updatedSvg.value,
      width: itemWidth.value,
      height: itemHeight.value,
      depth: itemDepth.value,
      name: itemName.value,
    };
  };
  return {
    openModal,
    closeModal,
    createModalOpen,
    croppedImageBlob,
    saveCroppedImageBlob,
    sendCroppedImageBlob,
    itemForCanvas,
    originalSvg,
    updatedSvg,
    backgroundRemovalLoading,
    imageWithoutBackground,
    imageHeight,
    imageWidth,
    steps,
    currentStep,
    setCannotFitError,
    cannotFitError,
    nextStep,
    prevStep,
    itemWidth,
    itemHeight,
    itemDepth,
    itemName,
    $reset,
  };
});
