<template>
  <div class="flex flex-col h-full">
    <span v-if="wizardStore.backgroundRemovalLoading">Laden</span>

    <div v-if="wizardStore.originalSvg">
      <div class="flex space-x-3">
        <div>
          <label
            for="width"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Breedte (in mm)</label
          >
          <div class="mt-2">
            <input
              type="number"
              name="width"
              id="width"
              @change.passive="calculatedRecommendedModelHeight"
              min="0"
              :max="makerjsStore.baseWidth"
              v-model="wizardStore.itemWidth"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <p class="mt-2 text-sm text-gray-500" id="width-description">
            Ga hier uit van het breedste punt van het object
          </p>
        </div>
        <div>
          <label
            for="height"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Hoogte (in mm)</label
          >
          <div class="mt-2">
            <input
              type="number"
              name="height"
              id="height"
              min="0"
              :max="makerjsStore.baseHeight"
              v-model="wizardStore.itemHeight"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <span v-if="calculatedModelHeight"
            >Berekende aanbevolen hoogte: {{ calculatedModelHeight }}</span
          >
          <p class="mt-2 text-sm text-gray-500" id="height-description">
            Ga hier uit van het hoogste punt van het object
          </p>
        </div>
        <div>
          <label
            for="depth"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Diepte/dikte (in mm)</label
          >
          <div class="mt-2">
            <input
              type="number"
              name="depth"
              id="depth"
              min="0"
              v-model="wizardStore.itemDepth"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <p class="mt-2 text-sm text-gray-500" id="depth-description">
            Ga hier uit van het dikste punt van het object
          </p>
        </div>
        <div>
          <label
            for="depth"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Naam</label
          >
          <div class="mt-2">
            <input
              type="text"
              name="name"
              id="name"
              required
              v-model="wizardStore.itemName"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <p class="mt-2 text-sm text-gray-500" id="name-description">
            Een naam om je item later makkelijk terug te kunnen vinden
          </p>
        </div>
      </div>
    </div>
    <div class="rounded-md bg-red-50 p-4" v-if="cannotFitError !== null">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">Item past niet</h3>
          <div class="mt-2 text-sm text-red-700">
            <p>{{ cannotFitError }}</p>
          </div>
        </div>
      </div>
    </div>
    <canvas
      id="trace-canvas"
      width="500"
      height="500"
      style="display: none"
    ></canvas>
    <SvgPathEditor
      v-if="wizardStore.originalSvg !== null"
      @svg-path-updated="calculateModelSize"
    ></SvgPathEditor>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useNewItemWizardStore } from "@/stores/newitemwizard";
import SvgPathEditor from "@/components/SvgPathEditor.vue";
import { useMakerStore } from "@/stores/maker";
import { storeToRefs } from "pinia";
import makerjs from "makerjs";

const wizardStore = useNewItemWizardStore();
const makerjsStore = useMakerStore();
const { cannotFitError, itemWidth, itemHeight } = storeToRefs(wizardStore);

onMounted(() => {
  wizardStore.sendCroppedImageBlob();
});
const imageSrc = ref(null);
const imageInput = ref(null);
const cropperVisible = ref(false);
const croppedImage = ref(null);
const croppedImageEl = ref(null);

const modelSize = ref(null);
const calculatedModelHeight = ref(null);

const svgRatio = ref(null);

const calculatedRecommendedModelHeight = (e) => {
  const scale = itemWidth.value / modelSize.value.width;

  calculatedModelHeight.value = Math.floor(modelSize.value.height * scale);
};

const calculateModelSize = (path) => {
  let extra = makerjs.importer.fromSVGPathData(path);
  extra.origin = [0, 0];
  extra.units = makerjs.unitType.Millimeter;

  modelSize.value = makerjs.measure.modelExtents(extra);
};
</script>

<style scoped></style>
