<template>
  <div>
    <div class="mt-6 flow-root overflow-y-scroll max-h-[480px]">
      <ul role="list" class="-my-5 divide-y divide-gray-200">
        <li v-for="model in models" :key="model.layer" class="py-4">
          <div
            :class="[
              selectedElement === model ? 'bg-yellow-200' : '',
              'flex items-center space-x-4 border-gray-200 border p-2',
            ]"
          >
            <div class="min-w-0 flex-1">
              <div class="flex justify-end">
                <button
                  @click="cloneItem(model)"
                  class="inline-flex items-center text-black px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset ring-gray-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                    />
                  </svg>
                </button>
                <button
                  @click="removeItem(model)"
                  class="inline-flex items-center text-white px-2.5 py-1 bg-red-600 text-xs font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>

              <SvgPreview :path="layerData(model).path"></SvgPreview>

              <p class="truncate text-sm font-medium text-gray-900">
                Naam: {{ layerName(model) }} <br />
                Afmetingen:
                {{ layerData(model).width }}mm x {{ layerData(model).height }}mm
              </p>
            </div>
            <div></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { useMakerStore } from "@/stores/maker";
import { computed } from "vue";
import SvgPreview from "@/components/SvgPreview.vue";

const store = useMakerStore();
const models = computed(() => store.uploadedModels);
const layerMeta = computed(() => store.layerMeta);
const selectedElement = computed(() => store.selectedElement);

const layerClass = (model) => {
  let baseClass = "flex items-center space-x-4";

  if (selectedElement === model) {
    return `${baseClass} bg-yellow-200`;
  }

  return baseClass;
};
const layerName = (model) => {
  return layerMeta.value[model].name ?? "Geupload item";
};

const boundingBoxFromPath = (path) => {
  // Your SVG string
  var svgString =
    "M266,258L263,261L261,271L263,280L263,307L255,324L250,352L247,358L240,366L235,381L234,410L237,499L236,509L239,518L245,524L249,525L286,527L318,526L329,524L337,521L341,517L344,501L339,378L334,366L326,357L322,350L317,327L306,305L303,288L305,277L305,264L303,260L300,257L292,255L278,254L268,258Z";

  // Create a new temporary SVG element
  var tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

  // Set the SVG content to your string
  tempSvg.innerHTML = '<path id="svgtemp" d="' + svgString + '"></path>';

  // Access the viewBox attribute of the SVG element
  var viewBoxValue = tempSvg.querySelector("#svgtemp");

  // Output the viewBox value
  viewBoxValue.getBBox();
  // path =
  //   "M 266 258 L 263 261 L 261 271 L 263 280 L 263 307 L 255 324 L 250 352 L 247 358 L 240 366 L 235 381 L 234 410 L 237 499 L 236 509 L 239 518 L 245 524 L 249 525 L 286 527 L 318 526 L 329 524 L 337 521 L 341 517 L 344 501 L 339 378 L 334 366 L 326 357 L 322 350 L 317 327 L 306 305 L 303 288 L 305 277 L 305 264 L 303 260 L 300 257 L 292 255 L 278 254 L 268 258 Z";
  // (path);
  // const values = path
  //   .split("L")
  //   .join(" ")
  //   .split("M")
  //   .join(" ")
  //   .split("z")
  //   .join("")
  //   .split(" ");
  // (values);
  // const max = Math.max.apply(Math, values);
  // const min = Math.min.apply(Math, values);
  //
  // (max);
  // (min);

  return "";
  // return `${max} ${min} ${max} ${max}`;
};

const layerData = (model) => {
  return layerMeta.value[model] ?? null;
};

const removeItem = (model) => {
  if (confirm("Weet je zeker dat je dit item wilt verwijderen?")) {
    store.removeItem(model);
  }
};

const cloneItem = (model) => {
  if (confirm("Weet je zeker dat je dit item wilt dupliceren?")) {
    try {
      store.cloneModel(model);
    } catch (err) {
      if (err.name === "SizeError") {
        alert(err.message);
      }
      return;
    }
  }
};
</script>

<style></style>
