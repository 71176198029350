export default class MarchingAnts {
  constructor() {
    this.canvas = document.getElementById("trace-canvas");
    this.ctx = this.canvas.getContext("2d");
    this.cw = this.canvas.width;
    this.ch = this.canvas.height;
    this.points = [];
    this.data = null;
    this.d3_geom_contourDx = [
      1,
      0,
      1,
      1,
      -1,
      0,
      -1,
      1,
      0,
      0,
      0,
      0,
      -1,
      0,
      -1,
      NaN,
    ];
    this.d3_geom_contourDy = [
      0,
      -1,
      0,
      0,
      0,
      -1,
      0,
      0,
      1,
      -1,
      1,
      1,
      0,
      -1,
      0,
      NaN,
    ];
  }

  contour(grid, start) {
    var s = start || this.d3_geom_contourStart(grid), // starting point
      c = [], // contour polygon
      x = s[0], // current x position
      y = s[1], // current y position
      dx = 0, // next x direction
      dy = 0, // next y direction
      pdx = NaN, // previous x direction
      pdy = NaN, // previous y direction
      i = 0;

    do {
      // determine marching squares index
      i = 0;
      if (grid(x - 1, y - 1)) i += 1;
      if (grid(x, y - 1)) i += 2;
      if (grid(x - 1, y)) i += 4;
      if (grid(x, y)) i += 8;

      // determine next direction
      if (i === 6) {
        dx = pdy === -1 ? -1 : 1;
        dy = 0;
      } else if (i === 9) {
        dx = 0;
        dy = pdx === 1 ? -1 : 1;
      } else {
        dx = this.d3_geom_contourDx[i];
        dy = this.d3_geom_contourDy[i];
      }

      // update contour polygon
      if (dx != pdx && dy != pdy) {
        c.push([x, y]);
        pdx = dx;
        pdy = dy;
      }

      x += dx;
      y += dy;
    } while (s[0] != x || s[1] != y);

    return c;
  }

  d3_geom_contourStart(grid) {
    var x = 0,
      y = 0;

    // search for a starting point; begin at origin
    // and proceed along outward-expanding diagonals
    while (true) {
      if (grid(x, y)) {
        return [x, y];
      }
      if (x === 0) {
        x = y + 1;
        y = 0;
      } else {
        x = x - 1;
        y = y + 1;
      }
    }
  }
  trace(img) {
    return new Promise((resolve, reject) => {
      let path = null;
      let image = new Image();
      image.src = img;
      image.onload = () => {
        this.ctx.canvas.width = this.cw = image.width;
        this.ctx.canvas.height = this.ch = image.height;
        // draw the image
        // (this time to grab the image's pixel data
        this.ctx.drawImage(
          image,
          this.canvas.width / 2 - image.width / 2,
          this.canvas.height / 2 - image.height / 2
        );

        // grab the image's pixel data
        const imgData = this.ctx.getImageData(
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
        this.data = imgData.data;

        // call the marching ants algorithm
        // to get the outline path of the image
        // (outline=outside path of transparent pixels
        this.points = this.contour(this.defineNonTransparent.bind(this));
        // (simplify(points));
        var dPath = this.simplify(this.points).flat().join(" ");

        resolve({
          path: `M${dPath}Z`,
          width: image.width,
          height: image.height,
        });
      };
    });
  }

  defineNonTransparent(x, y) {
    let a = this.data[(y * this.cw + x) * 4 + 3];
    return a > 20;
  }

  getSqDist(p1, p2) {
    const dx = p1[0] - p2[0],
      dy = p1[1] - p2[1];

    return dx * dx + dy * dy;
  }

  // square distance from a point to a segment
  getSqSegDist(p, p1, p2) {
    var x = p1[0],
      y = p1[1],
      dx = p2[0] - x,
      dy = p2[1] - y;

    if (dx !== 0 || dy !== 0) {
      var t = ((p[0] - x) * dx + (p[1] - y) * dy) / (dx * dx + dy * dy);

      if (t > 1) {
        x = p2[0];
        y = p2[1];
      } else if (t > 0) {
        x += dx * t;
        y += dy * t;
      }
    }

    dx = p[0] - x;
    dy = p[1] - y;

    return dx * dx + dy * dy;
  }
  // rest of the code doesn't care about point format

  // basic distance-based simplification
  simplifyRadialDist(points, sqTolerance) {
    var prevPoint = points[0],
      newPoints = [prevPoint],
      point;

    for (var i = 1, len = points.length; i < len; i++) {
      point = points[i];

      if (this.getSqDist(point, prevPoint) > sqTolerance) {
        newPoints.push(point);
        prevPoint = point;
      }
    }

    if (prevPoint !== point) newPoints.push(point);

    return newPoints;
  }

  simplifyDPStep(points, first, last, sqTolerance, simplified) {
    var maxSqDist = sqTolerance,
      index;

    for (var i = first + 1; i < last; i++) {
      var sqDist = this.getSqSegDist(points[i], points[first], points[last]);

      if (sqDist > maxSqDist) {
        index = i;
        maxSqDist = sqDist;
      }
    }

    if (maxSqDist > sqTolerance) {
      if (index - first > 1)
        this.simplifyDPStep(points, first, index, sqTolerance, simplified);
      simplified.push(points[index]);
      if (last - index > 1)
        this.simplifyDPStep(points, index, last, sqTolerance, simplified);
    }
  }

  // simplification using Ramer-Douglas-Peucker algorithm
  simplifyDouglasPeucker(points, sqTolerance) {
    var last = points.length - 1;

    var simplified = [points[0]];
    this.simplifyDPStep(points, 0, last, sqTolerance, simplified);
    simplified.push(points[last]);

    return simplified;
  }

  // both algorithms combined for awesome performance
  simplify(points, tolerance, highestQuality) {
    if (points.length <= 2) return points;

    var sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

    points = highestQuality
      ? points
      : this.simplifyRadialDist(points, sqTolerance);
    points = this.simplifyDouglasPeucker(points, sqTolerance);

    return points;
  }
}
