<template>
  <div>
    <NotificationWrapper></NotificationWrapper>
    <TransitionRoot as="template" :show="modalOpen">
      <Dialog
        as="div"
        class="relative z-[99999]"
        @close="wizardStore.closeModal()"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-[99999] overflow-y-auto">
          <div
            class="flex modal mt-10 items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-1/2 sm:p-6"
              >
                <CreateComponentWizard></CreateComponentWizard>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <TransitionRoot as="template" :show="saveModalOpened">
      <Dialog as="div" class="relative z-10" @close="saveModalClose">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 mt-8 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div>
                  <p
                    class="text-sm font-bold text-center p-2 bg-orange-100 text-orange-800"
                  >
                    Controleer voor versturen of alle afmetingen in mm zijn
                    ingevoerd.
                  </p>
                  <label
                    for="email"
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Email adres</label
                  >
                  <div class="mt-2">
                    <input
                      id="email"
                      v-model="saveModalEmail"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required=""
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <button
                    @click="sendToWordpress"
                    :disabled="isSendingToWordpress"
                    class="flex w-full justify-center rounded-md bg-indigo-600 disabled:bg-gray-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Verstuur
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <div class="grid grid-cols-6 space-x-1 bg-gray-100">
      <div class="col-span-1 bg-white">
        <button
          @click="addExtraModel"
          class="mt-2 inline-flex items-center rounded-md border border-transparent bg-orange-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
        >
          Onderdeel toevoegen
        </button>
      </div>
      <div class="col-span-4"></div>
      <div class="col-span-1 py-4">
        <button
          @click="saveModalOpen"
          class="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-orange-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-800 focus:ring-offset-2"
        >
          Opslaan en versturen
        </button>
      </div>
    </div>
    <div class="grid grid-cols-6 space-x-1 bg-gray-100">
      <div class="col-span-1 bg-white rounded px-2">
        <div class="mt-2">
          <span class="text-lg font-bold">Onderdelen</span>
          <ElementList></ElementList>
        </div>
      </div>
      <div class="col-span-4">
        <div class="bg-white rounded px-4 py-2">
          <div class="rounded-md bg-red-50 p-4" v-if="sizeError !== null">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-red-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">Item past niet</h3>
                <div class="mt-2 text-sm text-red-700">
                  <p>{{ sizeError }}</p>
                </div>
              </div>
            </div>
          </div>
          <div @wheel="panzoomInstance.zoomWithWheel" class="p-10 mt-10">
            <div id="panzoom-element" class="w-full">
              <SvgElement
                :outside-bounds="outsideBounds"
                :svg-element="store.svgValue"
                :panzoom-scale="panzoomScale"
                @element-moved="handleElementMove"
                @element-double-clicked="handleElementDoubleClicked"
                @element-hovered="handleElementHovered"
                @element-hovered-leave="handleElementHoveredLeave"
              ></SvgElement>
            </div>
          </div>
          <button
            type="button"
            class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            @click="panzoomInstance.reset()"
          >
            Reset zoom
          </button>
          <button
            type="button"
            class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            @click="removeDrawing"
          >
            Verwijderen
          </button>
        </div>
      </div>
      <div class="col-span-1 px-2">
        <div class="bg-white rounded px-4 py-2 flex flex-col">
          <span class="text-lg font-bold">Buitenafmetingen Inlay</span>
          <div class="mt-2">
            <label for="email" class="block text-sm font-medium text-gray-700"
              >Breedte (in mm)</label
            >
            <div class="mt-1">
              <input
                type="number"
                @change="store.updateOutsideBoundsModel"
                v-model.number="store.baseWidth"
                class="block w-full rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div class="mt-2">
            <label for="email" class="block text-sm font-medium text-gray-700"
              >Hoogte (in mm)</label
            >
            <div class="mt-1">
              <input
                type="number"
                @change="store.updateOutsideBoundsModel"
                v-model.number="store.baseHeight"
                class="block w-full rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <hr class="mt-4" />
          <div>
            <button
              class="w-full self-end inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              @click="sizePresetOpened = !sizePresetOpened"
            >
              Kies standaard koffer afmetingen
            </button>
            <div v-if="sizePresetOpened">
              <PresetSizeTemplates
                v-show="sizePresetOpened"
                @pick-preset-size="handlePickPresetSize"
              ></PresetSizeTemplates>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="activeElementRotation">
    Rotation
    <div>
      <label for="email" class="block text-sm font-medium text-gray-700"
        >Rotation</label
      >
      <div class="mt-1">
        <input
          type="number"
          @change="updateRotationForActiveElement"
          v-model.number="elementRotation"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <button
        type="button"
        @click="saveRotation"
        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Save rotation
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";

import Panzoom from "@panzoom/panzoom";
import makerjs from "makerjs";
import SvgElement from "@/components/SvgElement.vue";

import { useMakerStore } from "@/stores/maker";
import CreateComponentWizard from "@/components/CreateComponentWizard.vue";

import {
  DialogPanel,
  Dialog,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useNewItemWizardStore } from "@/stores/newitemwizard";
import ElementList from "@/components/ElementList.vue";
import SvgPathEditor from "@/components/SvgPathEditor.vue";
import { storeToRefs } from "pinia";
import PresetSizeTemplates from "@/components/PresetSizeTemplates.vue";
import { Notification, NotificationGroup, notify } from "notiwind";
import NotificationWrapper from "@/components/NotificationWrapper.vue";

const store = useMakerStore();
const wizardStore = useNewItemWizardStore();

const modalOpen = computed(() => wizardStore.createModalOpen);
const { sizeError } = storeToRefs(store);

const saveModalOpened = ref(false);
const saveModalEmail = ref("");

const sizePresetOpened = ref(false);

const handlePickPresetSize = (value) => {
  store.baseHeight = value.height;
  store.baseWidth = value.width;
  sizePresetOpened.value = false;
  store.updateOutsideBoundsModel();
};

const saveModalClose = () => {
  saveModalEmail.value = "";
  saveModalOpened.value = false;
};
const saveModalOpen = () => {
  saveModalOpened.value = true;
};

const panzoomInstance = ref(null);
const panzoomScale = ref(1);

const outsideBounds = ref(null);

const activeElementRotation = ref(null);
const activeElementRotationId = ref(null);
const elementRotation = ref(0);
const lastRotationPersisted = ref(0);
const showSelectedElement = ref(null);

const svgWrapper = ref(null);

const svgValue = ref(null);

const layerName = ref("");

const addExtraModel = () => {
  wizardStore.openModal();
};

const outsideModel = () => {
  let model = new makerjs.models.Rectangle(
    store.baseWidth.value,
    store.baseHeight.value
  );
  model.units = makerjs.unitType.Millimeter;
  return model;
};
const handleElementMove = (e) => {
  if (e.diffX === null && e.diffY === null) {
    return;
  }

  let newModel = makerjs.model.moveRelative(
    store.allModels.models[e.movedElement.id],
    [e.diffX, 0 - e.diffY]
  );
  store.allModels.models[e.movedElement.id] = newModel;
  store.renderSvg();
};
const removeDrawing = (model) => {
  if (confirm("Weet je zeker dat je alles wilt verwijderen?")) {
    store.$reset();
  }
};
const handleElementDoubleClicked = (e) => {
  activeElementRotation.value = e.target;
  activeElementRotationId.value = e.target.id;

  elementRotation.value = 0;
};

const handleElementHovered = (e) => {
  store.selectedElement = e.target.id;
};
const handleElementHoveredLeave = (e) => {
  store.selectedElement = null;
};

const updateRotationForActiveElement = () => {
  const rotation = elementRotation.value - lastRotationPersisted.value;
  let model = store.allModels.models[activeElementRotation.value.id];
  let newModel = makerjs.model.rotate(
    model,
    rotation,
    makerjs.measure.modelExtents(model).center
  );
  store.allModels.models[activeElementRotation.value.id] = newModel;
  store.renderSvg();
  lastRotationPersisted.value = elementRotation.value;

  // activeElementRotation.value.style.transform = `rotate(${elementRotation.value}deg)`;
};
const isSendingToWordpress = ref(false);

const sendToWordpress = async () => {
  isSendingToWordpress.value = true;
  const email = saveModalEmail.value;
  // Send the request using the Fetch API
  fetch("/wp-json/dxf-tool/send", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-WP-Nonce": wpApiSettings.nonce,
    },
    body: JSON.stringify({
      makerjs: store.exportToJson().makerjs,
      email: saveModalEmail.value,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        notify(
          {
            group: "error",
            title: data.error,
            text: data.error,
          },
          4000
        ); // 4s
      } else {
        saveModalClose();
        store.$reset();
        notify(
          {
            group: "success",
            title: "Ontwerp verzonden",
            text: "Het ontwerp is verzonden naar " + email,
          },
          4000
        ); // 4s
      }

      isSendingToWordpress.value = false;
    })
    .catch((error) => {
      isSendingToWordpress.value = false;

      console.error("Error:", error);
    });
};
const saveRotation = () => {
  activeElementRotation.value = null;
  activeElementRotationId.value = null;
  elementRotation.value = 0;
  lastRotationPersisted.value = 0;
  store.renderSvg();
};
const updateBounds = () => {
  if (document.getElementById("outsideBoundsModel")) {
    outsideBounds.value = document
      .getElementById("outsideBoundsModel")
      .getBoundingClientRect();
  }
};
onMounted(() => {
  const elem = document.getElementById("panzoom-element");
  panzoomInstance.value = Panzoom(elem, {
    maxScale: 2,
  });
  panzoomInstance.value.pan(0, 0);
  panzoomInstance.value.zoom(1, { animate: true });
  elem.addEventListener("panzoomzoom", function (e) {
    panzoomScale.value = e.detail;
    updateBounds();
  });

  store.renderSvg();
  updateBounds();
});

watch(
  () => store.svgValue,
  async () => {
    if (activeElementRotationId.value !== null) {
      activeElementRotation.value = document.getElementById(
        activeElementRotationId.value
      );
      updateRotationForActiveElement();
    }
    await nextTick();

    updateBounds();
  }
);
</script>

<style scoped>
g#svgGroup g {
  pointer-events: bounding-box;
}
</style>
