<template>
  <div class="max-h-1/2">
    <span>{{ wizardStore.steps[currentStep].title }}</span>
    <component :is="wizardStore.steps[currentStep].component"></component>
    <div class="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6">
      <button
        @click="wizardStore.nextStep"
        :disabled="currentStep === wizardStore.steps.length - 1"
        v-if="currentStep !== wizardStore.steps.length - 1"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      >
        Volgende
      </button>
      <button
        @click="createItem"
        class="inline-flex mr-2 w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        v-if="currentStep === wizardStore.steps.length - 1"
      >
        Opslaan
      </button>
      <button
        @click="wizardStore.prevStep"
        :disabled="currentStep === 0"
        class="inline-flex mr-2 w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        v-if="currentStep > 0"
      >
        Vorige
      </button>

      <div
        v-if="wizardStore.originalSvg"
        v-html="wizardStore.originalSvg"
        class="hidden svg-container"
      ></div>
    </div>
  </div>
</template>

<script setup>
import UploadCropPhoto from "@/components/WizardSteps/UploadCropPhoto.vue";
import { shallowRef } from "vue";

import { useNewItemWizardStore } from "@/stores/newitemwizard";
import { useMakerStore } from "@/stores/maker";
import { storeToRefs } from "pinia";
import makerjs from "makerjs";
const wizardStore = useNewItemWizardStore();
const makerjsStore = useMakerStore();

const { currentStep, cannotFitError } = storeToRefs(wizardStore);
const createItem = () => {
  wizardStore.setCannotFitError(null);
  let item = wizardStore.itemForCanvas();

  try {
    makerjsStore.canModelFitOnCanvas(item);
  } catch (err) {
    if (err.name === "SizeError") {
      wizardStore.setCannotFitError(err.message);
    }
    return;
  }
  makerjsStore.addModelToCanvas(item);

  wizardStore.closeModal();
};
</script>
