<script setup>
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";

// declare a ref to hold the element reference
// the name must match template ref value
const svgPathElement = ref(null);
const props = defineProps(["path"]);

const viewBox = ref("0 0 500 500");

onMounted(() => {
  const clientrect = svgPathElement.value.getBBox();

  viewBox.value =
    clientrect.x +
    " " +
    clientrect.y +
    " " +
    clientrect.width +
    " " +
    clientrect.height;
});
</script>

<template>
  <svg class="h-32 p-4 w-full" :viewBox="viewBox">
    <path
      ref="svgPathElement"
      :d="props.path"
      fill-opacity="0"
      stroke="#000"
      stroke-width="4px"
    ></path>
  </svg>
</template>

<style scoped></style>
