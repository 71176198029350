import { computed, reactive, ref } from "vue";
import { defineStore } from "pinia";
// eslint-disable-next-line no-undef
import * as makerjs from "makerjs";
import { cloneDeep } from "lodash-es";
import { notify } from "notiwind";

class SizeError extends Error {
  constructor(message) {
    super(message);
    this.name = "SizeError";
  }
}

export const useMakerStore = defineStore(
  "makerjs",
  () => {
    const baseWidth = ref(400);
    const baseHeight = ref(300);

    const layerMeta = ref({});

    const selectedElement = ref(null);

    const svgValue = ref(null);
    const sizeError = ref(null);
    const convertBlobToBase64 = async (blob) => {
      // blob data
      return await blobToBase64(blob);
    };

    function setSizeError(value) {
      sizeError.value = value;
    }

    function $reset() {
      baseWidth.value = 400;
      baseHeight.value = 300;

      layerMeta.value = {};

      selectedElement.value = null;
      let outside = new makerjs.models.Rectangle(
        baseWidth.value,
        baseHeight.value
      );
      outside.units = makerjs.unitType.Millimeter;
      outside.layer = "outsideBoundsModel";
      outside.caption = {
        text: `${baseWidth.value} x ${baseHeight.value} mm`,
        anchor: new makerjs.paths.Line(
          [0, baseHeight.value / 20],
          [baseWidth.value / 4, baseHeight.value / 20]
        ),
      };
      Object.assign(allModels, {
        models: {
          outsideBoundsModel: outside,
        },
        paths: {},
        origin: [0, 0],
      });
      renderSvg();
    }

    const blobToBase64 = (blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    function exportToJson() {
      return {
        makerjs: allModels,
      };
    }

    let outside = new makerjs.models.Rectangle(
      baseWidth.value,
      baseHeight.value
    );
    outside.units = makerjs.unitType.Millimeter;
    outside.layer = "outsideBoundsModel";
    outside.caption = {
      text: `${baseWidth.value} x ${baseHeight.value} mm`,
      anchor: new makerjs.paths.Line(
        [0, baseHeight.value / 20],
        [baseWidth.value / 4, baseHeight.value / 20]
      ),
    };

    function createModelFromItem(item) {
      let extra = makerjs.importer.fromSVGPathData(item.path);
      extra.origin = [0, 0];
      extra.units = makerjs.unitType.Millimeter;

      const measuredSize = makerjs.measure.modelExtents(extra);

      const scale = item.width / measuredSize.width;

      extra = makerjs.model.scale(extra, scale);
      extra = makerjs.model.originate(extra);
      const newSize = makerjs.measure.modelExtents(extra);
      extra = makerjs.model.outline(extra, 1.5);
      extra = makerjs.model.addCaption(
        extra,
        `${item.depth} mm`,
        newSize.center,
        newSize.center
      );

      return makerjs.model.zero(extra);
    }

    function cloneModel(originalModelId) {
      let model = makerjs.cloneObject(allModels.models[originalModelId]);
      model = makerjs.model.originate(model);
      model = makerjs.model.zero(model);
      let position = getFirstOpenPositionForNewModel(model);
      if (position === null) {
        notify(
          {
            group: "error",
            title: "Geen ruimte",
            text: "Het item past niet meer op de tekening. Maak de tekening groter of verwijder andere items om dit te laten passen.",
          },
          10000
        );

        return;
      }
      let uuid = crypto.randomUUID();
      model.layer = uuid;

      layerMeta.value[uuid] = cloneDeep(layerMeta.value[originalModelId]);

      allModels.models[uuid] = model;
      renderSvg();
    }

    function addModelToCanvas(item) {
      let model = createModelFromItem(item);

      getFirstOpenPositionForNewModel(model);
      let uuid = crypto.randomUUID();
      model.layer = uuid;

      layerMeta.value[uuid] = item;

      allModels.models[uuid] = model;

      renderSvg();
    }

    function getFirstOpenPositionForNewModel(newModel) {
      let gridSize = 5;

      let workspaceSize = makerjs.measure.modelExtents(
        allModels.models["outsideBoundsModel"]
      );
      let workspaceWidth = workspaceSize.width;
      let workspaceHeight = workspaceSize.height;

      let newMeasurement = makerjs.measure.modelExtents(newModel);

      let oldPosition = [0, 0];
      let position = [0, 0];
      for (
        let x = 0;
        x < workspaceWidth - newMeasurement.width;
        x += gridSize
      ) {
        for (
          let y = 0;
          y < workspaceHeight - newMeasurement.height;
          y += gridSize
        ) {
          oldPosition = position;
          position = [x, y];
          let available = true;
          makerjs.model.moveRelative(newModel, [
            position[0] - oldPosition[0],
            position[1] - oldPosition[1],
          ]);
          let newMeasurement = makerjs.measure.modelExtents(newModel);
          for (let model of uploadedModels.value) {
            if (available === false) {
              break;
            }
            let measurement = makerjs.measure.modelExtents(
              allModels.models[model]
            );

            if (
              makerjs.measure.isMeasurementOverlapping(
                newMeasurement,
                measurement
              )
            ) {
              available = false;
            }
          }
          if (available === true) {
            return position;
          }
        }
      }

      return null;
    }

    function renderSvg() {
      makerjs.model.originate(allModels);

      let options = {};

      for (let model in allModels.models) {
        if (model !== "outsideBoundsModel") {
          options[allModels.models[model].layer] = {
            cssStyle: "pointer-events: bounding-box",
          };
        }
      }
      svgValue.value = makerjs.exporter.toSVG(allModels, {
        useSvgPathOnly: false,
        className: "panzoom-exclude",
        layerOptions: options,
        fontSize: "5pt",
      });
      ("render called");
    }

    const allModels = reactive({
      models: {
        outsideBoundsModel: outside,
      },
      paths: {},
      origin: [0, 0],
    });

    function updateOutsideBoundsModel() {
      try {
        setSizeError(null);

        setBaseDimensions(baseWidth.value, baseHeight.value);
      } catch (err) {
        if (err.name === "SizeError") {
          notify(
            {
              group: "error",
              title: "Geen ruimte",
              text: err.message,
            },
            10000
          );
          const { width, height } = getMaxDimensionsForUploadedModels();
          if (baseWidth.value < width) {
            baseWidth.value = Math.round(width);
          }

          if (baseHeight.value < height) {
            baseHeight.value = Math.round(height);
          }
        }
      }
    }

    const model = reactive({ models: {} });

    function getMaxDimensionsForUploadedModels() {
      let totalMeasurement = {
        high: [0, 0],
        low: [0, 0],
        center: [0, 0],
        width: 0,
        height: 0,
      };
      if (uploadedModels.value.length > 0) {
        totalMeasurement = makerjs.measure.modelExtents(
          allModels.models[uploadedModels.value[0]]
        );
      }
      for (let model of uploadedModels.value) {
        let measurement = makerjs.measure.modelExtents(allModels.models[model]);

        if (measurement.low[0] < totalMeasurement.low[0]) {
          totalMeasurement.low[0] = measurement.low[0];
        }
        if (measurement.low[1] < totalMeasurement.low[1]) {
          totalMeasurement.low[1] = measurement.low[1];
        }

        if (measurement.high[0] > totalMeasurement.high[0]) {
          totalMeasurement.high[0] = measurement.high[0];
        }
        if (measurement.high[1] > totalMeasurement.high[1]) {
          totalMeasurement.high[1] = measurement.high[1];
        }
      }

      totalMeasurement.width =
        totalMeasurement.high[0] - totalMeasurement.low[0];
      totalMeasurement.height =
        totalMeasurement.high[1] - totalMeasurement.low[1];

      return totalMeasurement;
    }

    function setBaseDimensions(width, height) {
      let maxDimensions = getMaxDimensionsForUploadedModels();
      if (width < maxDimensions.width || height < maxDimensions.height) {
        notify(
          {
            group: "error",
            title: "Geen ruimte",
            text: "De nieuwe buitenafmetingen zijn te klein voor de huidige onderdelen. Maak de tekening groter of verwijder andere items om dit te laten passen.",
          },
          10000
        );

        return;
      }

      let baseModel = new makerjs.models.Rectangle(width, height);
      baseModel.units = "mm";
      baseModel.caption = {
        text: `${baseWidth.value} x ${baseHeight.value} mm`,
        anchor: new makerjs.paths.Line(
          [0, baseHeight.value / 20],
          [baseWidth.value / 4, baseHeight.value / 20]
        ),
      };

      allModels.models.outsideBoundsModel = baseModel;
      renderSvg();
    }

    const uploadedModels = computed(() => {
      let models = [];
      for (let model in allModels.models) {
        if (model !== "outsideBoundsModel") {
          models.push(model);
        }
      }

      return models;
    });

    const removeItem = (uuid) => {
      delete allModels.models[uuid];
      renderSvg();
    };
    const canModelFitOnCanvas = (item) => {
      let model = createModelFromItem(item);

      let boundsModelMeasurement = makerjs.measure.modelExtents(
        allModels.models.outsideBoundsModel
      );
      let newModelMeasurement = makerjs.measure.modelExtents(model);

      if (
        newModelMeasurement.width >= boundsModelMeasurement.width ||
        newModelMeasurement.height >= boundsModelMeasurement.height
      ) {
        throw new SizeError(
          "Het item is te groot voor de tekening. Maak de tekening groter om dit te laten passen."
        );
      }

      let position = getFirstOpenPositionForNewModel(model);

      if (position === null) {
        throw new SizeError(
          "Het item past niet meer op de tekening. Maak de tekening groter of verwijder andere items om dit te laten passen."
        );
      }

      return true;
    };

    return {
      model,
      setBaseDimensions,
      baseWidth,
      baseHeight,
      allModels,
      addModelToCanvas,
      renderSvg,
      svgValue,
      uploadedModels,
      layerMeta,
      removeItem,
      cloneModel,
      updateOutsideBoundsModel,
      exportToJson,
      selectedElement,
      canModelFitOnCanvas,
      setSizeError,
      getMaxDimensionsForUploadedModels,
      sizeError,
      $reset,
    };
  },
  {
    persist: true,
  }
);
