<template>
  <div id="canvas_container"></div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from "vue";

const paper = ref(null);
const emit = defineEmits(["svgPathUpdated"]);

onMounted(() => {
  loadInitialEditor();
});
// Create an empty project and a view for the canvas:
import { useNewItemWizardStore } from "@/stores/newitemwizard";

const wizardStore = useNewItemWizardStore();
const points = ref([]);

const dragStartX = ref(0);
const dragStartY = ref(0);

const drawnPath = ref(null);

function onDragMove(dx, dy) {
  let point = points.value.find((value) => {
    return value.id === this.id;
  });

  point.attr("cx", dragStartX.value + dx);
  point.attr("cy", dragStartY.value + dy);
  drawSvgPath();
}

function onDragStart() {
  dragStartX.value = this.attr("cx");
  dragStartY.value = this.attr("cy");
}

function onDragComplete() {
  dragStartX.value = 0;
  dragStartY.value = 0;
  emit("svgPathUpdated", wizardStore.updatedSvg);
}

function drawSvgPath() {
  if (points.value.length === 0) {
    return;
  }
  // Start with the 'move to' command and the first point
  // (points.value[0])
  let pathString =
    "M" + points.value[0].attr("cx") + "," + points.value[0].attr("cy");

  // Add a line to the path for each point
  for (let i = 1; i < points.value.length; i++) {
    pathString +=
      "L" + points.value[i].attr("cx") + "," + points.value[i].attr("cy");
  }

  // If you want to close the path (connect the last point to the first one)
  pathString += "Z";
  wizardStore.updatedSvg = pathString;

  if (drawnPath.value === null) {
    drawnPath.value = paper.value
      .path(pathString)
      .attr({
        id: "testpath",
        stroke: "black",
        "stroke-width": 3,
      })
      .toBack();
  } else {
    drawnPath.value.attr("path", pathString);
  }
}
function loadInitialEditor() {
  paper.value = Raphael(
    "canvas_container",
    wizardStore.imageWidth,
    wizardStore.imageHeight
  );

  const pathArray = Raphael.parsePathString(wizardStore.originalSvg);
  points.value = pathArray
    .map((command) => ({ x: command[1], y: command[2] }))
    .filter((point) => {
      return point.x && point.y;
    })
    .map((point) => {
      return paper.value
        .circle(point.x, point.y, 5)
        .attr({
          id: "testpath",
          fill: "blue",
          cursor: "move",
        })
        .drag(onDragMove, onDragStart, onDragComplete);
    });
  drawSvgPath();
  emit("svgPathUpdated", wizardStore.originalSvg);

  paper.value
    .image(
      URL.createObjectURL(wizardStore.croppedImageBlob),
      0,
      0,
      wizardStore.imageWidth,
      wizardStore.imageHeight
    )
    .attr({
      opacity: 0.7,
    })
    .toBack();
}
</script>

<style></style>
